import { DefaultTheme, configureFonts } from 'react-native-paper';

import theme from '../../theme';
import { fontConfig } from '../typography/fonts';
import { white, black } from '../colors';
import { FoodlesTheme } from '../theming';

export const BaseTheme: FoodlesTheme = {
  ...theme,
  ...DefaultTheme,
  dark: false,
  roundness: 4,
  colors: {
    ...DefaultTheme.colors,
    primaryLight: DefaultTheme.colors.primary,
    primaryAlt: DefaultTheme.colors.primary,
    primaryContrastText: white,
    primaryLightContrastText: black,
    accentLight: DefaultTheme.colors.accent,
    accentAlt: DefaultTheme.colors.accent,
    accentContrastText: white,
    accentLightContrastText: black,
    surface: white,
    text: black,
    background: '#000000',
    onSurface: '#000000',
  },
  fonts: configureFonts(fontConfig),
  animation: {
    scale: 1.0,
  },
};
