import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Client, IntercomUser, User } from './typings';
import { Transaction } from '../food/typings';

export type IntercomProfileQueryData = {
  intercom: IntercomUser;
};

export const CLIENT_INTERCOM_QUERY: DocumentNode = gql`
  query IntercomProfile {
    intercom: intercomProfile
      @rest(type: "IntercomProfile", path: "/client/current/intercom/") {
      user_id
      web_user_hash
      android_user_hash
      ios_user_hash
      email
      fullname
    }
  }
`;

export type ClientProfile = Pick<
  Client,
  | 'id'
  | 'badge'
  | 'credit'
  | 'canteen'
  | 'canteen_name'
  | 'onboarding_step'
  | 'mobile_app_onboarding'
  | 'operation_country'
  | 'can_top_up'
  | 'can_see_transaction_history'
  | 'can_export_organization_transactions'
> & {
  user: Pick<User, 'fullname' | 'email' | 'id'>;
};

export type ClientProfileQueryData = {
  profile: ClientProfile;
};

export const CLIENT_PROFILE_QUERY: DocumentNode = gql`
  query ClientProfile {
    profile: clientProfile
      @rest(type: "ClientProfile", path: "/client/current/") {
      id
      badge
      credit
      canteen
      canteen_name
      onboarding_step
      mobile_app_onboarding
      can_top_up
      can_see_transaction_history
      can_export_organization_transactions
      user @type(name: "User") {
        fullname
        email
        id
      }
      operation_country @type(name: "OperationCountry") {
        id
        country_code
        default_language_code
        currency
        timezone
      }
    }
  }
`;

export type ClientNotificationSettings = Pick<
  Client,
  'notify_sale' | 'notify_refill'
>;

export type ClientNotificationSettingsQueryData = {
  settings: ClientNotificationSettings;
};

export const CLIENT_NOTIFICATION_SETTINGS_QUERY: DocumentNode = gql`
  query {
    settings: clientProfile {
      notify_sale
      notify_refill
    }
  }
`;

export type UserProfile = Pick<User, 'first_name' | 'last_name' | 'email'>;

export type UserProfileQueryData = {
  profile: UserProfile;
};

export const USER_PROFILE_QUERY: DocumentNode = gql`
  query UserProfile {
    profile {
      first_name
      last_name
      email
    }
  }
`;

export type TransactionsListQueryData = {
  transactions: Transaction[];
};

export interface TransactionListQueryVariables {
  limit: number;
  offset: number;
}

export const TRANSACTIONS_LIST_QUERY = gql`
  query getTransactions($limit: Int, $offset: Int) {
    transactions(offset: $offset, limit: $limit) {
      results {
        id
        valeur
        created_at
        amount {
          amount
          currency
        }
      }
      count
    }
  }
`;

export const TRANSACTION_BY_ID_QUERY = gql`
  query getTransactionById($id: Int!) {
    transactionById(id: $id) {
      id
      valeur
      amount {
        amount
        currency
      }
      created_at
      company
      items {
        id
        name
        image_id
        image
        saved_price
      }
    }
  }
`;

export type ClientReceiptData = Pick<
  Client,
  | 'id'
  | 'society_receipt'
  | 'address_receipt'
  | 'postcode_receipt'
  | 'city_receipt'
  | 'country_receipt'
  | 'phone_receipt'
> & { user: Pick<User, 'id'> };

export interface TransactionReceiptVariables {
  transactionId: number;
}

export interface TransactionReceiptQueryData {
  clientProfile: ClientReceiptData;
  transactionReceiptUrl: { url: string };
}

export const TRANSACTION_RECEIPT_DATA_QUERY: DocumentNode = gql`
  query TransactionReceiptData($transactionId: Int!) {
    transactionReceiptUrl(transactionId: $transactionId) {
      url
    }
    clientProfile {
      id
      society_receipt
      address_receipt
      postcode_receipt
      city_receipt
      country_receipt
      phone_receipt
      user_fullname
      user {
        id
      }
    }
  }
`;
