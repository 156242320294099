import {
  blueStone,
  jaggedIce,
  peach,
  persimmon,
  gulfStream,
  waxFlower,
} from '../colors';
import { BaseTheme } from './base-theme';
import { FoodlesTheme } from '../theming';

export const BlueStonePersimmonTheme: FoodlesTheme = {
  ...BaseTheme,
  colors: {
    ...BaseTheme.colors,
    primary: blueStone,
    primaryLight: jaggedIce,
    primaryAlt: gulfStream,
    accent: persimmon,
    accentLight: peach,
    accentAlt: waxFlower,
    notification: peach,
  },
};
